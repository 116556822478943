import { useRef } from "react"
import SearchForm from "@/common/search-form/search-form"
import Block from "@/components/wrappers/block"
import styles from "./archive-header.module.css"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import cx from "classnames"

export default function ArchiveHeader({ block }) {
  const { tag, heading, search, description } = block.attrs
  const searchPanel = useRef()

  return (
    <Block block={block} width="bleed" gap="none" className={styles.wrapper}>
      <Breadcrumbs gap="none" width="full" type="large" />
      <div className={styles.content}>
        {tag && <span className="tag">{tag}</span>}
        <h1>{heading}</h1>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className={cx(styles.form)} ref={searchPanel}>
        <SearchForm data={search} expand={block.attrs.expand} />
      </div>
    </Block>
  )
}
